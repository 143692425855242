export default {
  'color-bw-100': '#F8F9FA',
  'color-bw-200': '#E1E7EC',
  'color-bw-300': '#D5DDE5',
  'color-bw-400': '#CCD4DB',
  'color-bw-500': '#AEBECD',
  'color-bw-600': '#929FB1',
  'color-bw-700': '#6E7A8A',
  'color-bw-800': '#404B5A',
  'color-bw-900': '#202833',
  'color-blue-100': '#E6F6FF',
  'color-blue-200': '#BAE3FF',
  'color-blue-300': '#7CC4FA',
  'color-blue-400': '#47A3F3',
  'color-blue-500': '#2186EB',
  'color-blue-600': '#0967D2',
  'color-blue-700': '#0552B5',
  'color-blue-800': '#03449E',
  'color-blue-900': '#01337D',
  'color-blue-1000': '#002159',
  'color-green-100': '#E3F9E5',
  'color-green-200': '#E3F9E5',
  'color-green-300': '#C1EAC5',
  'color-green-400': '#A3D9A5',
  'color-green-500': '#57AE5B',
  'color-green-600': '#3F9142',
  'color-green-700': '#2F8132',
  'color-green-800': '#207227',
  'color-green-900': '#0E5814',
  'color-green-1000': '#05400A',
  'color-red-100': '#FFE3E3',
  'color-red-200': '#FFBDBD',
  'color-red-300': '#FF9B9B',
  'color-red-400': '#F86A6A',
  'color-red-500': '#EF4E4E',
  'color-red-600': '#E12D39',
  'color-red-700': '#CF1124',
  'color-red-800': '#AB091E',
  'color-red-900': '#8A041A',
  'color-red-1000': '#610316',
  'color-yellow-100': '#ffc700',
  'color-yellow-200': '#a58000',
  'color-text': '#404B5A',
  'drop-shadow': '0px 2px 4px rgba(0,0,0,0.1)',
  'drop-shadow-lower': '0px 4px 16px rgba(0,0,0,0.1)',
  // 'font-family': `'aktiv-grotesk','San Francisco',-apple-system,'BlinkMacSystemFont','".SFSNText-Regular"','Segoe UI','Ubuntu','Helvetica',sans-serif`,
  'font-family': `proxima-nova,'Proxima Nova','San Francisco',-apple-system,'BlinkMacSystemFont','".SFSNText-Regular"','Segoe UI','Ubuntu','Helvetica',sans-serif`,
  'font-size-title': 16,
  'font-size': 14,
  'font-size-subtitle': 12,
  'border-radius': 4,
  'z-index-tracker-calendar-body': 499,
  'z-index-tracker-calendar-heading': 500,
  'z-index-tracker-calendar-labels': 600,
  'z-index-wizard-content': 899,
  'z-index-wizard-footer': 900,
  'z-index-tooltip': 1000,
  'z-index-popover': 1100,
  'z-index-modal': 1200
}
